"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));
var _defineProperties = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-properties"));
var _getOwnPropertyDescriptors = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors"));
var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));
var _getOwnPropertyDescriptor = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _getOwnPropertySymbols = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols"));
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _defineProperty3 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/defineProperty"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/typeof"));
var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _stringify = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/json/stringify"));
var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));
var _CoreManager = _interopRequireDefault(require("./CoreManager"));
var _ParseError = _interopRequireDefault(require("./ParseError"));
function ownKeys(object, enumerableOnly) {
  var keys = (0, _keys.default)(object);
  if (_getOwnPropertySymbols.default) {
    var symbols = (0, _getOwnPropertySymbols.default)(object);
    if (enumerableOnly) symbols = (0, _filter.default)(symbols).call(symbols, function (sym) {
      return (0, _getOwnPropertyDescriptor.default)(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
      var _context2;
      (0, _forEach.default)(_context2 = ownKeys(source, true)).call(_context2, function (key) {
        (0, _defineProperty3.default)(target, key, source[key]);
      });
    } else if (_getOwnPropertyDescriptors.default) {
      (0, _defineProperties.default)(target, (0, _getOwnPropertyDescriptors.default)(source));
    } else {
      var _context3;
      (0, _forEach.default)(_context3 = ownKeys(source)).call(_context3, function (key) {
        (0, _defineProperty2.default)(target, key, (0, _getOwnPropertyDescriptor.default)(source, key));
      });
    }
  }
  return target;
}
var XHR = null;
if (typeof XMLHttpRequest !== 'undefined') {
  XHR = XMLHttpRequest;
}
var useXDomainRequest = false;
if (typeof XDomainRequest !== 'undefined' && !('withCredentials' in new XMLHttpRequest())) {
  useXDomainRequest = true;
}
function ajaxIE9(method
/*: string*/, url
/*: string*/, data
/*: any*/, options
/*:: ?: FullOptions*/) {
  return new _promise.default(function (resolve, reject) {
    var xdr = new XDomainRequest();
    if (options && typeof options.requestTask === 'function') {
      options.requestTask(xdr);
    }
    xdr.onload = function () {
      var response;
      try {
        response = JSON.parse(xdr.responseText);
      } catch (e) {
        reject(e);
      }
      if (response) {
        resolve({
          response: response
        });
      }
    };
    xdr.onerror = xdr.ontimeout = function () {
      // Let's fake a real error message.
      var fakeResponse = {
        responseText: (0, _stringify.default)({
          code: _ParseError.default.X_DOMAIN_REQUEST,
          error: 'IE\'s XDomainRequest does not supply error info.'
        })
      };
      reject(fakeResponse);
    };
    xdr.onprogress = function () {
      if (options && typeof options.progress === 'function') {
        options.progress(xdr.responseText);
      }
    };
    xdr.open(method, url);
    xdr.send(data);
  });
}
var RESTController = {
  ajax: function (method
  /*: string*/, url
  /*: string*/, data
  /*: any*/, headers
  /*:: ?: any*/, options
  /*:: ?: FullOptions*/) {
    if (useXDomainRequest) {
      return ajaxIE9(method, url, data, headers, options);
    }
    var res, rej;
    var promise = new _promise.default(function (resolve, reject) {
      res = resolve;
      rej = reject;
    });
    promise.resolve = res;
    promise.reject = rej;
    var attempts = 0;
    var dispatch = function dispatch() {
      if (XHR == null) {
        throw new Error('Cannot make a request: No definition of XMLHttpRequest was found.');
      }
      var handled = false;
      var aborted = false;
      var xhr = new XHR();
      if (options && typeof options.requestTask === 'function') {
        options.requestTask(xhr);
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState !== 4 || handled) {
          return;
        }
        handled = true;
        if (xhr.status >= 200 && xhr.status < 300) {
          var response;
          try {
            response = JSON.parse(xhr.responseText);
            if (typeof xhr.getResponseHeader === 'function') {
              var _context;
              if ((0, _includes.default)(_context = xhr.getAllResponseHeaders() || '').call(_context, 'x-parse-job-status-id: ')) {
                response = xhr.getResponseHeader('x-parse-job-status-id');
              }
            }
          } catch (e) {
            promise.reject(e.toString());
          }
          if (response) {
            promise.resolve({
              response: response,
              status: xhr.status,
              xhr: xhr
            });
          }
        } else if (aborted && xhr.status === 0) {
          promise.resolve({
            response: {},
            status: 0,
            xhr: xhr
          });
        } else if (xhr.status >= 500 || xhr.status === 0) {
          // retry on 5XX or node-xmlhttprequest error
          if (++attempts < _CoreManager.default.get('REQUEST_ATTEMPT_LIMIT')) {
            // Exponentially-growing random delay
            var delay = Math.round(Math.random() * 125 * Math.pow(2, attempts));
            (0, _setTimeout2.default)(dispatch, delay);
          } else if (xhr.status === 0) {
            promise.reject('Unable to connect to the Parse API');
          } else {
            // After the retry limit is reached, fail
            promise.reject(xhr);
          }
        } else {
          promise.reject(xhr);
        }
      };
      headers = headers || {};
      if (typeof headers['Content-Type'] !== 'string') {
        headers['Content-Type'] = 'text/plain'; // Avoid pre-flight
      }
      if (_CoreManager.default.get('IS_NODE')) {
        headers['User-Agent'] = 'Parse/' + _CoreManager.default.get('VERSION') + ' (NodeJS ' + process.versions.node + ')';
      }
      if (_CoreManager.default.get('SERVER_AUTH_TYPE') && _CoreManager.default.get('SERVER_AUTH_TOKEN')) {
        headers['Authorization'] = _CoreManager.default.get('SERVER_AUTH_TYPE') + ' ' + _CoreManager.default.get('SERVER_AUTH_TOKEN');
      }
      if (options && typeof options.progress === 'function') {
        if (xhr.upload) {
          xhr.upload.addEventListener('progress', function (oEvent) {
            if (oEvent.lengthComputable) {
              options.progress(oEvent.loaded / oEvent.total);
            } else {
              options.progress(null);
            }
          });
        } else if (xhr.addEventListener) {
          xhr.addEventListener('progress', function (oEvent) {
            if (oEvent.lengthComputable) {
              options.progress(oEvent.loaded / oEvent.total);
            } else {
              options.progress(null);
            }
          });
        }
      }
      xhr.onabort = function () {
        aborted = true;
      };
      xhr.open(method, url, true);
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
      xhr.send(data);
    };
    dispatch();
    return promise;
  },
  request: function (method
  /*: string*/, path
  /*: string*/, data
  /*: mixed*/, options
  /*:: ?: RequestOptions*/) {
    options = options || {};
    var url = _CoreManager.default.get('SERVER_URL');
    if (url[url.length - 1] !== '/') {
      url += '/';
    }
    url += path;
    var payload = {};
    if (data && (0, _typeof2.default)(data) === 'object') {
      for (var k in data) {
        payload[k] = data[k];
      }
    }
    if (method !== 'POST') {
      payload._method = method;
      method = 'POST';
    }
    payload._ApplicationId = _CoreManager.default.get('APPLICATION_ID');
    var jsKey = _CoreManager.default.get('JAVASCRIPT_KEY');
    if (jsKey) {
      payload._JavaScriptKey = jsKey;
    }
    payload._ClientVersion = _CoreManager.default.get('VERSION');
    var useMasterKey = options.useMasterKey;
    if (typeof useMasterKey === 'undefined') {
      useMasterKey = _CoreManager.default.get('USE_MASTER_KEY');
    }
    if (useMasterKey) {
      if (_CoreManager.default.get('MASTER_KEY')) {
        delete payload._JavaScriptKey;
        payload._MasterKey = _CoreManager.default.get('MASTER_KEY');
      } else {
        throw new Error('Cannot use the Master Key, it has not been provided.');
      }
    }
    if (_CoreManager.default.get('FORCE_REVOCABLE_SESSION')) {
      payload._RevocableSession = '1';
    }
    var installationId = options.installationId;
    var installationIdPromise;
    if (installationId && typeof installationId === 'string') {
      installationIdPromise = _promise.default.resolve(installationId);
    } else {
      var installationController = _CoreManager.default.getInstallationController();
      installationIdPromise = installationController.currentInstallationId();
    }
    return installationIdPromise.then(function (iid) {
      payload._InstallationId = iid;
      var userController = _CoreManager.default.getUserController();
      if (options && typeof options.sessionToken === 'string') {
        return _promise.default.resolve(options.sessionToken);
      } else if (userController) {
        return userController.currentUserAsync().then(function (user) {
          if (user) {
            return _promise.default.resolve(user.getSessionToken());
          }
          return _promise.default.resolve(null);
        });
      }
      return _promise.default.resolve(null);
    }).then(function (token) {
      if (token) {
        payload._SessionToken = token;
      }
      var payloadString = (0, _stringify.default)(payload);
      return RESTController.ajax(method, url, payloadString, {}, options).then(function (_ref) {
        var response = _ref.response,
          status = _ref.status;
        if (options.returnStatus) {
          return _objectSpread({}, response, {
            _status: status
          });
        } else {
          return response;
        }
      });
    }).catch(function (response
    /*: { responseText: string }*/) {
      // Transform the error into an instance of ParseError by trying to parse
      // the error string as JSON
      var error;
      if (response && response.responseText) {
        try {
          var errorJSON = JSON.parse(response.responseText);
          error = new _ParseError.default(errorJSON.code, errorJSON.error);
        } catch (e) {
          // If we fail to parse the error text, that's okay.
          error = new _ParseError.default(_ParseError.default.INVALID_JSON, 'Received an error with invalid JSON from Parse: ' + response.responseText);
        }
      } else {
        error = new _ParseError.default(_ParseError.default.CONNECTION_FAILED, 'XMLHttpRequest failed: ' + (0, _stringify.default)(response));
      }
      return _promise.default.reject(error);
    });
  },
  _setXHR: function (xhr
  /*: any*/) {
    XHR = xhr;
  }
};
module.exports = RESTController;