var _Object$getOwnPropertyDescriptor = require("../core-js/object/get-own-property-descriptor");
var _Reflect$get = require("../core-js/reflect/get");
var superPropBase = require("./superPropBase");
function _get(target, property, receiver) {
  if (typeof Reflect !== "undefined" && _Reflect$get) {
    module.exports = _get = _Reflect$get;
  } else {
    module.exports = _get = function _get(target, property, receiver) {
      var base = superPropBase(target, property);
      if (!base) return;
      var desc = _Object$getOwnPropertyDescriptor(base, property);
      if (desc.get) {
        return desc.get.call(receiver);
      }
      return desc.value;
    };
  }
  return _get(target, property, receiver || target);
}
module.exports = _get;